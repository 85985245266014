<template>
  <div class="d-flex align-center">
    <v-menu
      v-model="searchLayersIsOpen"
      offset-y
      :close-on-content-click="false"
    >
      <template #activator="{ on, attrs }">
        <div class="px-1 pt-2">
          <v-icon v-bind="attrs" height="24" width="24" class="pa-1" v-on="on">
            mdi-layers
          </v-icon>
        </div>
      </template>
      <v-list>
        <v-list-item-group
          v-model="searchLayers"
          active-class="primary--text"
          multiple
        >
          <v-list-item dense @click.stop="onAllLayersSelect">
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in viewer.searchable"
            :key="index"
            :value="item.id"
            dense
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu v-model="searchIsOpen" :close-on-content-click="false" offset-y>
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="query"
          clearable
          dense
          hide-details
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          append-inner-icon="mdi-close"
          type="text"
          style="max-width: 300px"
          @click:clear="onClear"
          @keydown.enter="runSearch"
          v-on="on"
        />
        <!--        @focus="searchIsOpen = true"-->
        <!--        @blur="searchIsOpen = false"-->
      </template>
      <v-expand-transition>
        <v-card>
          <v-list v-for="groupUid in searchGroups" :key="groupUid">
            <v-subheader>{{
              searchItems.groups[groupUid].source.name
            }}</v-subheader>
            <v-list-item
              v-for="(item, index) in searchItems.groups[groupUid].results"
              :key="index"
              @click.stop="onSelect(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-expand-transition>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GisSearch',
  data: () => ({
    query: '',

    searchLayers: [],

    searchItems: undefined,
    searchIsLoading: false,
    searchIsOpen: false,
    searchLayersIsOpen: false,
  }),
  computed: {
    ...mapGetters('gisViewer', {
      viewer: 'viewer',
    }),
    searchGroups() {
      if (!this.searchItems?.count) return [];
      return (
        (this.searchItems?.groups && Object.keys(this.searchItems.groups)) || []
      );
    },
  },
  watch: {
    searchLayers(val) {
      this.$store.dispatch('gisViewer/loadSearchSources', {
        layersIds: val,
      });
    },
  },
  methods: {
    runSearch() {
      this.searchIsOpen = true;
      this.searchIsLoading = true;
      this.viewer.widgets.search
        .search(this.query)
        .then((event) => {
          this.mapSearchResults(event);
        })
        .finally(() => {
          this.searchIsLoading = false;
        });
    },
    mapSearchResults(event) {
      this.searchItems = event.results.reduce((data, search) => {
        const source = search.source;
        const results = search.results;
        return {
          groups: {
            ...(data?.groups || {}),
            [source.uid]: search,
          },
          count: (data.count || 0) + results.length,
        };
      }, {});
    },
    onSelect(item) {
      this.searchIsOpen = false;
      this.$emit('selected', { item });
    },
    onClear() {
      this.searchIsOpen = false;
      this.searchItems = undefined;
      this.$emit('clear');
    },
    onAllLayersSelect() {
      this.$nextTick(() => {
        this.searchLayersIsOpen = false;
        this.searchLayers = [];
      });
    },
  },
};
</script>
